/******* screen-large.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* hide-mobile.less 2013-1-16 *******/
#mobile-navi,
.togglenavigation,
.cb-mobile-access,
.cb-mobile-navigation,
.body-mobile {
  display: none;
}
.cb-mobile-navigation {
  opacity: 0;
  -o-transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  transition: none;
}
.cb-mobile-invisible,
.body-non-mobile {
  display: block;
}
/******* navigation-large.less 2013-1-16 *******/
.navi,
.navi > .item,
.dock {
  display: block;
  float: left;
}
.menu {
  display: block;
}
/* LEVEL 1 */
div.sub1 {
  margin: 31px 0 31px 40px;
}
.sub1 > .item {
  margin: 0 20px;
  position: relative;
}
.sub1 > .item.init {
  margin-left: 0;
}
.sub1 > .item:hover > .menu,
.sub1 > .item:focus > .menu {
  color: #3F7793;
}
@media only screen and (max-width: 1320px) {
  .sub1 > .item {
    margin: 0 14px;
  }
  .sub1 > .item > .menu {
    font-size: 15px;
    line-height: 1.86666667;
  }
}
div.sub1 .menu {
  font-size: 18px;
  line-height: 1.55;
  color: #000;
}
div.sub1 .menu:hover,
div.sub1 .menu:focus,
div.sub1 .menu.path {
  color: #3F7793;
}
div.sub1 > .item > .cb-toggle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
div.sub1 > .item > .cb-toggle:hover,
div.sub1 > .item > .cb-toggle:focus {
  cursor: pointer;
}
#edit div.sub1 > .item > .cb-toggle {
  display: none;
}
div.sub1 > .item.item-empty > .cb-toggle {
  display: none;
}
/* LEVEL 2 */
div.sub2 {
  position: fixed;
  top: 90px;
  left: 0;
  width: 100%;
  height: 350px;
  background: #f5f4f2;
  text-align: center;
  height: 0;
  overflow: hidden;
  -o-transition: all 0.4s cubic-bezier(0.36, 0.88, 0.55, 0.98);
  -moz-transition: all 0.4s cubic-bezier(0.36, 0.88, 0.55, 0.98);
  -webkit-transition: all 0.4s cubic-bezier(0.36, 0.88, 0.55, 0.98);
  transition: all 0.4s cubic-bezier(0.36, 0.88, 0.55, 0.98);
}
#wrapper.cb-toggle-beacon-active div.sub2 {
  transition: all 0.218s 0.5s;
}
#edit #navigation div.sub2 {
  display: none;
}
.sub2 > .item {
  float: none;
  width: 240px;
  margin-right: 20px;
  margin-top: 70px;
  margin-bottom: 70px;
  display: inline-block;
  opacity: 0;
  -o-transition: all 0.218s;
  -moz-transition: all 0.218s;
  -webkit-transition: all 0.218s;
  transition: all 0.218s;
}
div.sub2 .menu {
  text-align: left;
  font-size: 18px;
  color: #000;
  line-height: 1.5;
}
div.sub2 .menu:hover,
div.sub2 .menu:focus {
  color: #3F7793;
}
div.sub1 > .item.cb-toggle-target-active div.sub2 {
  height: var(--js-elementHeight);
  -o-transition: all 0.4s cubic-bezier(0.36, 0.88, 0.55, 0.98);
  -moz-transition: all 0.4s cubic-bezier(0.36, 0.88, 0.55, 0.98);
  -webkit-transition: all 0.4s cubic-bezier(0.36, 0.88, 0.55, 0.98);
  transition: all 0.4s cubic-bezier(0.36, 0.88, 0.55, 0.98);
  z-index: 2;
}
#wrapper.cb-toggle-beacon-active div.sub1 > .item.cb-toggle-target-active div.sub2 {
  -o-transition: all 0.4s cubic-bezier(0.36, 0.88, 0.55, 0.98);
  -moz-transition: all 0.4s cubic-bezier(0.36, 0.88, 0.55, 0.98);
  -webkit-transition: all 0.4s cubic-bezier(0.36, 0.88, 0.55, 0.98);
  transition: all 0.4s cubic-bezier(0.36, 0.88, 0.55, 0.98);
}
div.sub1 > .item.cb-toggle-target-active div.sub2 > .item {
  opacity: 1;
}
/* LEVEL 3 */
div.sub3 {
  width: 100%;
  margin-top: 14px;
}
.sub3 > .item {
  width: 100%;
  margin: 5px 0;
}
div.sub3 .menu {
  font-size: 16px;
  line-height: 1.5;
  color: #000;
  padding-left: 28px;
  background: url(/images/arrow-black.svg) no-repeat 0 0.3em;
}
div.sub3 .menu .edit {
  background: none !important;
}
div.sub3 .menu:hover,
div.sub3 .menu:focus,
div.sub3 .menu.path {
  color: #3F7793;
  background-image: url(/images/arrow-blue.svg);
}
/******* layout-large.less 2013-1-16 *******/
#home {
  float: left;
  margin-right: 0;
}
#head {
  width: 74%;
  margin: 120px 13% 0;
}
.topservices {
  float: right;
  margin-left: 0;
}
#pfad {
  margin: 85px 13% 0;
  width: 74%;
}
#find {
  display: block !important;
}
.phone {
  margin-right: 40px;
  margin-left: 0;
}
.shortsearch {
  float: right;
  display: block;
}
#newsletter {
  display: block;
}
#basewidth,
#content,
#multimood,
#specialmood,
#navigation {
  padding-right: 50px;
  padding-left: 50px;
}
.cb-layout2 #content,
.cb-layout3 #content,
.cb-layout4 #content,
.cb-layout5 #content {
  margin-top: 0;
}
.c2.cb-layout2 #content,
.c2.cb-layout3 #content,
.c2.cb-layout4 #content,
.c2.cb-layout5 #content {
  padding-top: 23.6%;
}
#edit.cb-layout5 #content {
  margin-top: 226px;
}
.c2#edit.cb-layout5 #content {
  margin-top: 23.6%;
  padding-top: 0;
}
.cb-layout2 #maincontent,
.cb-layout3 #maincontent,
.cb-layout4 #maincontent,
.cb-layout5 #maincontent {
  margin-top: 226px;
}
#edit.cb-layout5 #maincontent {
  margin-top: 0;
}
.jsmoodlink {
  display: none;
}
#view div.area div.fold div.ctrl {
  padding: 0 130px;
}
@media only screen and (max-width: 1400px) {
  #find {
    display: none !important;
  }
  .moodtext {
    font-size: 14px;
    min-height: 48px;
  }
  .moodservices {
    right: 10px;
  }
  #multimood,
  #specialmood {
    padding-right: 0;
    padding-left: 0;
  }
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 1.57068063%;
  margin-left: 1.57068063%;
}
.area .part,
.area > .grid table {
  margin-right: 1.57068063%;
  margin-left: 1.57068063%;
  width: 96.85863874%;
}
.area .tiny {
  width: 46.85863874%;
}
.area > .slim {
  width: 50%;
}
.area > .slim h2,
.area > .slim .foot,
.area > .slim .part,
.area > .slim.grid table {
  margin-right: 3.14136126%;
  margin-left: 3.14136126%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 93.71727749%;
}
.area > .slim .tiny {
  width: 43.71727749%;
}
.cb-layout2 .main,
.cb-layout4 .main,
.cb-layout5 .main {
  width: 100%;
}
.cb-layout2 .main > .unit,
.cb-layout4 .main > .unit,
.cb-layout5 .main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout2 .main h2,
.cb-layout4 .main h2,
.cb-layout5 .main h2,
.cb-layout2 .main .foot,
.cb-layout4 .main .foot,
.cb-layout5 .main .foot {
  margin-right: 13%;
  margin-left: 13%;
}
.cb-layout2 .main .part,
.cb-layout4 .main .part,
.cb-layout5 .main .part,
.cb-layout2 .main > .grid table,
.cb-layout4 .main > .grid table,
.cb-layout5 .main > .grid table {
  margin-right: 13%;
  margin-left: 13%;
  width: 74%;
}
.cb-layout2 .main > .slim .part,
.cb-layout4 .main > .slim .part,
.cb-layout5 .main > .slim .part,
.cb-layout2 .main > .slim.grid table,
.cb-layout4 .main > .slim.grid table,
.cb-layout5 .main > .slim.grid table {
  width: 74%;
}
.base {
  width: 100%;
}
.base > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.base h2,
.base .foot {
  margin-right: 13%;
  margin-left: 13%;
}
.base .part,
.base > .grid table {
  margin-right: 13%;
  margin-left: 13%;
  width: 74%;
}
.base > .slim .part,
.base > .slim.grid table {
  width: 74%;
}
.cb-layout3 .base {
  width: 100%;
}
.cb-layout3 .base > .unit {
  margin-right: 1.171875%;
  margin-left: 1.171875%;
  width: 97.65625%;
}
.cb-layout3 .base h2,
.cb-layout3 .base .foot {
  margin-right: 1.2%;
  margin-left: 1.2%;
}
.cb-layout3 .base .part,
.cb-layout3 .base > .grid table {
  margin-right: 1.2%;
  margin-left: 1.2%;
  width: 97.6%;
}
.cb-layout3 .base > .slim {
  width: 22.65625%;
}
.cb-layout3 .base > .slim h2,
.cb-layout3 .base > .slim .foot,
.cb-layout3 .base > .slim .part,
.cb-layout3 .base > .slim.grid table {
  margin-right: 5.17241379%;
  margin-left: 5.17241379%;
}
.cb-layout3 .base > .slim .part,
.cb-layout3 .base > .slim.grid table {
  width: 89.65517241%;
}
.cb-layout3 .base > .slim .tiny {
  width: 89.65517241%;
}
.south {
  width: 100%;
}
.south > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.south h2,
.south .foot {
  margin-right: 13%;
  margin-left: 13%;
}
.south .part,
.south > .grid table {
  margin-right: 13%;
  margin-left: 13%;
  width: 74%;
}
.south > .slim .part,
.south > .slim.grid table {
  width: 74%;
}
.unit.form .tile .name,
.unit a.capt {
  float: left;
  width: 33%;
}
.unit.form .tile .chop,
.unit.form .tile textarea,
.unit.form .tile input.text,
.unit.form .tile .ctrl,
.unit input.capt {
  float: right;
  width: 63%;
}
.part.auth.tile .text,
.part.auth.tile .password {
  width: 63%;
}
.part.payp.tile,
.part.payp.tile .papc {
  position: static;
}
.part.payp.tile .papc {
  margin: 0;
  padding: 0;
}
.part.payp.tile input.numb {
  position: static;
  float: left;
}
.part.payp.tile .name {
  float: left;
  width: 60%;
}
.slim .tiny.auth.tile .password {
  width: 100%;
}
.slim .tiny.payp.tile,
.slim .tiny.payp.tile .papc {
  position: relative;
}
.slim .tiny.payp.tile .papc {
  margin-bottom: -2em;
  padding-bottom: 2em;
}
.slim .tiny.payp.tile input.numb {
  position: absolute;
  bottom: 0.4em;
}
.slim .tiny.payp.tile input.numb.grow {
  position: absolute !important;
}
.slim .tiny.payp.tile .name {
  float: none;
  width: 100%;
}
/*.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}*/
.main {
  width: 76.4%;
  margin-left: 11.8%;
}
.cb-layout2 .main,
.cb-layout4 .main,
.cb-layout5 .main {
  width: 100%;
  margin-left: 0;
}
.base {
  width: 100%;
}
.cb-layout3 .base {
  width: 102.4%;
  margin-left: -1.2%;
}
.south {
  width: 100%;
}
.topcontent .area.areaTen {
  width: calc(100% - 266px);
}
.topcontent .area.areaEleven {
  float: right;
  width: 242px;
}
.cb-layout2 #content div.main div.seam,
.cb-layout4 #content div.main div.seam,
.cb-layout5 #content div.main div.seam {
  padding: 0 25.6%;
}
.cb-layout1 div.base div.unit div.part,
.cb-layout1 div.base div.unit div.head h2,
.cb-layout2 div.base div.unit div.part,
.cb-layout2 div.base div.unit div.head h2,
.cb-layout4 div.base div.unit div.part,
.cb-layout4 div.base div.unit div.head h2,
.cb-layout5 div.base div.unit div.part,
.cb-layout5 div.base div.unit div.head h2 {
  margin-left: 13%;
  margin-right: 13%;
}
div.south div.unit div.part,
div.south div.unit div.head h2 {
  margin-left: 13%;
  margin-right: 13%;
}
#disp {
  left: 50%;
  margin-left: -175px;
  width: 350px;
  font-size: 15px;
  font-size: 1.5rem;
  top: 120px !important;
}
#disp.zoom {
  left: 50%;
  margin-left: -385px;
  width: 770px;
}
#disp.tube {
  left: 50%;
  margin-left: -330px;
  width: 660px;
}
#disp.site > .body {
  overflow: auto;
  max-height: 400px;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
h1,
h2 {
  overflow-wrap: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
/* scan-large.less 2013-1-16 */
.scan li,
.scan li > .cb-hybrid,
.scan span,
.same > .cb-hybrid {
  padding: 0 0.3em;
}
th.prev,
th.next {
  font-weight: normal;
}
.scan th.prev,
.scan td.prev {
  text-align: left;
}
.scan th.next,
.scan td.next {
  text-align: right;
}
.same,
.this .same,
.same > .cb-hybrid {
  background: #3F7793;
  color: #fff;
}
.same,
.mese .same > span {
  background-color: #3F7793;
}
.this .same,
.this .same > .cb-hybrid,
.mese.this .same > span {
  background-color: #305a6f;
}
.mese .same {
  background: none;
}
/******* module-filter-large.less 2013-1-16 *******/
/*.beta span > .cb-hybrid,
.days td > .cb-hybrid,
.week td > .cb-hybrid,
.year td > .cb-hybrid,
.cats li > .cb-hybrid {
  text-decoration: underline;
}*/
.mese td > .cb-hybrid {
  background-color: #44809e;
}
.mese td > .cb-hybrid:hover,
.mese td > .cb-hybrid:focus {
  background-color: #376981;
}
.mese tbody th,
.epoc td > .cb-hybrid,
.year td > .cb-hybrid,
.mese td > .cb-hybrid,
.week td > .cb-hybrid,
.days td > .cb-hybrid {
  padding: 0 0.3em;
}
.mese tbody th,
.mese tbody td {
  padding: 0 4% 0 2%;
}
.mese td > .cb-hybrid,
.mese td > span {
  padding: 0 0.1em;
}
.mese td > span {
  display: block;
}
.tabs .cb-hybrid,
.tabs .same > span,
.time .cb-hybrid,
.time .same > span,
.keys input,
.scan select {
  padding: 0;
}
.scan select {
  margin: 0;
  width: 100%;
}
.keys input {
  float: left;
  margin: 0 2% 0 0;
  padding: 0.23em;
  width: 71%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.keys button.cb-hybrid {
  display: block;
  float: left;
}
.font9 {
  font-size: 9px;
  font-size: 0.9rem;
}
.font12 {
  font-size: 12px;
  font-size: 1.2rem;
}
.font15 {
  font-size: 15px;
  font-size: 1.5rem;
}
.font18 {
  font-size: 18px;
  font-size: 1.8rem;
}
.font21 {
  font-size: 21px;
  font-size: 2.1rem;
}
.font24 {
  font-size: 24px;
  font-size: 2.4rem;
}
.font27 {
  font-size: 27px;
  font-size: 2.7rem;
}
.font30 {
  font-size: 30px;
  font-size: 3rem;
}
.font32 {
  font-size: 32px;
  font-size: 3.2rem;
}
/*# sourceMappingURL=./screen-large.css.map */